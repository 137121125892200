.video_item {
    transition: all .3 ease;
    position: relative;
    z-index: 1;
}

.video_item:hover {
    cursor: pointer !important;
    /* transform: scale(1.1) !important; */
}

.video_item::before {
    content: '' !important;
    position: absolute !important;

    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    width: 0 !important;
    background-color: rgba(255, 255, 255, .5) !important;
    transition: all .3s linear;
}

.video_item:hover::before {
    width: 100% !important;
    font-weight: 600 !important;
}